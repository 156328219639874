import React, { useState, useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

import HelpModal from "./HelpModal";
import SubscribeModal from "./SubscribeModal";
import ShareButton from "./ShareButton";
import { Box } from "@mui/material";
import FeedbackModal from "./FeedbackModal";
import AnalyticsModal from "./AnalyticsModal";

/* eslint-disable react/prop-types */
export default function Header(props) {
  const [weeklyPrompt, setWeeklyPrompt] = useState({});
  const [promptCompleted, setPromptCompleted] = useState(false);

  useEffect(() => {
    setPromptCompleted(props.promptCompleted);
  }, [props.promptCompleted]);

  useEffect(() => {
    setWeeklyPrompt(props.weeklyPrompt);
  }, [props.weeklyPrompt]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            flexGrow={1}
            flexBasis={0}
            display={{ xs: "none", md: "inherit" }}
          />
          {/*App Name*/}
          <Typography
            variant="h1"
            component="div"
            fontWeight="bold"
            paddingTop="10px"
            paddingBottom="10px"
            marginLeft={{ xs: "5px", md: "0px" }}
            sx={{
              fontSize: { xs: "min(2.5em, 5vw)", md: "2.5em" },
            }}
          >
            Weekly Serve
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              display: "flex",
              borderRadius: 10,
            }}
          >
            <Box flexGrow={1} />

            <Box display={{ xs: "none", md: "inherit" }}>
              <HelpModal></HelpModal>
            </Box>
            <Box display={{ xs: "flex", md: "none" }}>
              <HelpModal></HelpModal>
            </Box>

            {/*Settings Icon*/}
            <FeedbackModal></FeedbackModal>

            {/*Share Icon*/}
            <ShareButton
              displayIcon={true}
              weeklyPrompt={weeklyPrompt}
              promptCompleted={promptCompleted}
            ></ShareButton>

            {/*Analytics*/}
            <AnalyticsModal displayIcon={true}></AnalyticsModal>

            {/*Subscribe Icon*/}
            <SubscribeModal></SubscribeModal>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
