import React, { useEffect } from "react";
import { firestore } from "./Firebase";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Home from "./components/Home";
import Unsubscribe from "./components/Unsubscribe";

function App() {
  const profile = {
    prompt_completed_this_week: false,
    total_prompts_completed: 0,
    completed_prompt_ids: [],
    last_date_prompt_completed: {},
    saw_helper_modal: false,
  };

  const fetchAllPrompts = async () => {
    try {
      const dailyPromptRef = firestore.collection("used-weekly-prompts");
      const dailyPromptSnapshot = await dailyPromptRef.get();

      const fetchedPrompts = dailyPromptSnapshot.docs.map((doc) => ({
        ...doc.data(),
      }));

      localStorage.setItem("all_prompts", JSON.stringify(fetchedPrompts));
    } catch (error) {
      console.error("Error fetching prompt data: ", error);
    }
  };

  const fetchWeeklyPrompt = async () => {
    try {
      const weeklyPromptRef = firestore.collection("weekly-prompt");
      const weeklyPromptSnapshot = await weeklyPromptRef.get();

      const fetchedPrompt = weeklyPromptSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      localStorage.setItem("weekly_prompt", JSON.stringify(fetchedPrompt));
      const e = new StorageEvent("storage", {
        storageArea: window.localStorage,
        key: "weekly_prompt",
        newValue: fetchedPrompt,
      });
      window.dispatchEvent(e);
    } catch (error) {
      console.error("Error fetching prompt data: ", error);
    }
  };

  const fetchGlobalCount = async () => {
    try {
      firestore
        .collection("global-stats")
        .orderBy("date", "desc")
        .limit(1)
        .get()
        .then((globalStatsSnapshot) => {
          if (globalStatsSnapshot) {
            const doc = globalStatsSnapshot.docs[0];
            const globalStats = {
              id: globalStatsSnapshot.docs[0].id,
              ...doc.data(),
            };

            localStorage.setItem("global_stats", JSON.stringify(globalStats));
            const e = new StorageEvent("storage", {
              storageArea: window.localStorage,
              key: "global_stats",
              newValue: globalStats,
            });
            window.dispatchEvent(e);
          }
        });
    } catch (error) {
      console.error("Error fetching global count: ", error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("profile")) {
      localStorage.setItem("profile", JSON.stringify(profile));
    }
    fetchWeeklyPrompt();
    fetchAllPrompts();
    fetchGlobalCount();
  }, []);

  return (
    <>
      {/*Auth*/}
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/unsubscribe" Component={Unsubscribe} />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
