import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

/* eslint-disable react/prop-types */
const WeeklyPrompt = (props) => {
  const [currentUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [prompt, setPromptData] = useState([]);
  const [promptCompleted, setPromptCompleted] = useState(false);

  useEffect(() => {
    setPromptCompleted(props.promptCompleted);
  }, [props.promptCompleted]);

  function setWeeklyPrompt() {
    if (localStorage.getItem("weekly_prompt")) {
      const fetchedPrompt = JSON.parse(localStorage.getItem("weekly_prompt"));

      if (fetchedPrompt) {
        setPromptData(fetchedPrompt);
        resetCurrentUser(fetchedPrompt);
      }
    }
  }

  function resetCurrentUser(fetchedWeeklyPrompt) {
    if (localStorage.getItem("weekly_prompt")) {
      const localWeeklyPrompt = JSON.parse(
        localStorage.getItem("weekly_prompt")
      );
      if (
        localWeeklyPrompt[0].weekly_prompt_id !==
        fetchedWeeklyPrompt[0].weekly_prompt_id
      )
        localStorage.setItem(
          "profile",
          JSON.stringify({ ...currentUser, prompt_completed_this_week: false })
        );
    }
  }

  useEffect(() => {
    setWeeklyPrompt();
  }, []);

  useEffect(() => {
    const storageEventHandler = (event) => {
      if (event.key === "weekly_prompt") {
        setWeeklyPrompt();
      }
    };

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <>
      {prompt[0] && (
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: "min(3.5em, 7vw)",
              fontWeight: "bold",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: prompt[0].prompt }} />
          </Typography>
          {prompt[0].description && (
            <Typography
              marginTop="2dvh"
              variant="h2"
              sx={{
                fontSize: "min(2em, 5vw)",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: prompt[0].description }}
              />
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default WeeklyPrompt;
