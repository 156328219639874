import React, { useEffect, useState } from "react";

import Header from "./Header";
import { Box } from "@mui/material";
import WeeklyPrompt from "./WeeklyPrompt";
import DidItModal from "./DidItModal";

export default function Home() {
  const [weeklyPrompt, setWeeklyPrompt] = useState([]);
  const [promptCompleted, setPromptCompleted] = useState({});

  useEffect(() => {
    const weeklyPrompt = JSON.parse(localStorage.getItem("weekly_prompt"));
    if (weeklyPrompt) {
      setWeeklyPrompt(weeklyPrompt[0]);
    }
  }, [localStorage.getItem("weekly_prompt")]);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("profile"));
    if (currentUser) {
      setPromptCompleted(currentUser.prompt_completed_this_week);
    }
  }, [localStorage.getItem("profile")]);

  useEffect(() => {
    const storageEventHandler = (event) => {
      if (event.key === "weekly_prompt") {
        const weeklyPrompt = JSON.parse(localStorage.getItem("weekly_prompt"));
        if (weeklyPrompt) {
          setWeeklyPrompt(weeklyPrompt[0]);
        }
      }
    };

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <>
      <Box
        height="100dvh"
        width="100vw"
        margin="auto"
        display="flex"
        flexDirection="column"
        overflow="auto"
      >
        <Box flexGrow={2}>
          <Header
            weeklyPrompt={weeklyPrompt}
            promptCompleted={promptCompleted}
          />
        </Box>
        <Box
          flexGrow={2}
          align="center"
          paddingLeft={{ xs: "10vw", md: "12vw", lg: "15vw" }}
          paddingRight={{ xs: "10vw", md: "12vw", lg: "15vw" }}
        >
          <WeeklyPrompt promptCompleted={promptCompleted}></WeeklyPrompt>
        </Box>
        <Box flexGrow={1} align="center">
          <DidItModal weeklyPrompt={weeklyPrompt}></DidItModal>
        </Box>
      </Box>
    </>
  );
}
