import React, { useState, useEffect } from "react";
import { IconButton, Button, SnackbarContent, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import ShareIcon from "@mui/icons-material/Share";

/* eslint-disable react/prop-types */
export default function Header(props) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [weeklyPrompt, setWeeklyPrompt] = useState({});
  const [hidden, setHidden] = useState(false);
  const [displayIcon, setDisplayIcon] = useState(false);
  const [message, setMessage] = useState("");
  const [promptCompleted, setPromptCompleted] = useState(false);
  const [inModal, setInModal] = useState(false);

  const handleSnackbarOpen = () => {
    if (promptCompleted) {
      navigator.clipboard.writeText(
        "I completed this week's Weekly Serve! " +
          weeklyPrompt.emoji_description +
          " -> Check it out at weeklyserve.com"
      );
    } else {
      navigator.clipboard.writeText(
        "I am working on this week's Weekly Serve! " +
          weeklyPrompt.emoji_description +
          " -> Check it out at weeklyserve.com"
      );
    }
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setWeeklyPrompt(props.weeklyPrompt);
  }, [props.weeklyPrompt]);

  useEffect(() => {
    setHidden(props.hidden);
  }, [props.hidden]);

  useEffect(() => {
    setDisplayIcon(props.displayIcon);
  }, [props.displayIcon]);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  useEffect(() => {
    setPromptCompleted(props.promptCompleted);
  }, [props.promptCompleted]);

  useEffect(() => {
    setInModal(props.inModal);
  }, [props.inModal]);

  const inModalSize = () => {
    if (inModal) {
      return "medium";
    } else return "large";
  };

  return (
    <>
      {!displayIcon && (
        <Button
          aria-label="share-button"
          variant="contained"
          size={inModalSize()}
          hidden={hidden}
          onClick={handleSnackbarOpen}
          sx={{
            color: "white",
            backgroundColor: "#6fbf54",
            "&:hover": {
              backgroundColor: "#63ab4b",
            },
            borderRadius: "10px",
            width: "inherit",
            fontSize: "inherit",
          }}
        >
          <Typography
            paddingRight="1vh"
            paddingLeft="1vh"
            fontSize="inherit"
            textAlign="center"
          >
            {message || "Share"}
          </Typography>
          <ShareIcon />
        </Button>
      )}

      {displayIcon && (
        <>
          <IconButton
            size="large"
            color="inherit"
            aria-label="share"
            onClick={handleSnackbarOpen}
            sx={{
              borderRadius: "inherit",
              width: "inherit",
              paddingRight: { xs: "5px", md: "10px" },
              paddingLeft: { xs: "5px", md: "10px" },
            }}
          >
            <ShareIcon />
            {message && (
              <Typography
                paddingLeft={1}
                paddingRight={1}
                width="inherit"
                textAlign="left"
              >
                {message || "Share"}
              </Typography>
            )}
          </IconButton>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          message="Copied to clipboard"
        />
      </Snackbar>
    </>
  );
}
