import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import UserStats from "./UserStats";
import { firestore } from "../Firebase";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ShareButton from "./ShareButton";
import AnalyticsModal from "./AnalyticsModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});
dayjs.tz.setDefault("America/Los_Angeles");

/* eslint-disable react/prop-types */
export default function DidItModal(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [didIt, setDidIt] = useState(false);
  const [weeklyPrompt, setWeeklyPrompt] = useState({});
  const [globalStats, setGlobalStats] = useState({});
  const [inModal, setInModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const handleOpen = () => {
    handleDidIt();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDidIt = () => {
    updateGlobalStatsDb();
    updateProfile();
    setDidIt(true);
  };

  const updateProfile = () => {
    if (currentUser) {
      const promptDate = new Date(weeklyPrompt.date_used.seconds * 1000);
      let completed_prompt_ids = [];

      if (currentUser.completed_prompt_ids) {
        completed_prompt_ids = [
          ...currentUser.completed_prompt_ids,
          weeklyPrompt.weekly_prompt_id,
        ];
      } else {
        completed_prompt_ids.push(weeklyPrompt.weekly_prompt_id);
      }

      const profile = {
        ...currentUser,
        prompt_completed_this_week: true,
        total_prompts_completed: (currentUser.total_prompts_completed += 1),
        completed_prompt_ids: completed_prompt_ids,
        last_date_prompt_completed: new Date(promptDate),
      };

      localStorage.setItem("profile", JSON.stringify(profile));
      const e = new StorageEvent("storage", {
        storageArea: window.localStorage,
        key: "profile",
        newValue: profile,
      });
      window.dispatchEvent(e);
    }
  };

  const updateGlobalStatsDb = () => {
    try {
      if (globalStats) {
        setLoading(true);
        firestore
          .collection("global-stats")
          .doc(globalStats.id)
          .update({
            count: firebase.firestore.FieldValue.increment(1),
          });

        const newGlobalStats = {
          id: globalStats.id,
          count: globalStats.count + 1,
          date: globalStats.date,
        };

        localStorage.setItem("global_stats", JSON.stringify(newGlobalStats));
        const e = new StorageEvent("storage", {
          storageArea: window.localStorage,
          key: "global_stats",
          newValue: newGlobalStats,
        });
        window.dispatchEvent(e);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching global count: ", error);
      setLoading(false);
    }
  };

  const isPromptCompleted = () => {
    if (currentUser) {
      if (currentUser.completed_prompt_ids) {
        return currentUser.completed_prompt_ids.includes(
          weeklyPrompt.weekly_prompt_id
        );
      }
    }
    return false;
  };

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("profile")));
    setDidIt(isPromptCompleted());
  }, [localStorage.getItem("profile")]);

  useEffect(() => {
    setGlobalStats(JSON.parse(localStorage.getItem("global_stats")));
  }, [sessionStorage.getItem("global_stats")]);

  useEffect(() => {
    const storageEventHandler = (event) => {
      if (event.key === "global_stats") {
        setGlobalStats(JSON.parse(localStorage.getItem("global_stats")));
      } else if (event.key === "profile") {
        setCurrentUser(JSON.parse(localStorage.getItem("profile")));
        setDidIt(isPromptCompleted());
      }
    };

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  useEffect(() => {
    setInModal(props.inModal);
    setLoading(false);
  }, [props.inModal]);

  useEffect(() => {
    setWeeklyPrompt(props.weeklyPrompt);
    setLoading(false);
  }, [props.weeklyPrompt]);

  useEffect(() => {
    setDidIt(isPromptCompleted());
  }, [weeklyPrompt]);

  const getButtonFontSize = () => {
    if (inModal) {
      return "1em";
    } else return { xs: "1em", md: "1.5em" };
  };

  const getButtonWidth = () => {
    if (inModal) {
      return 140;
    } else return { xs: 140, md: 150 };
  };

  const didItModalBody = (
    <>
      {weeklyPrompt && !inModal && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "500px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            borderRadius: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1dvh",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ color: "black" }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Box>
          <UserStats
            globalCount={globalStats ? globalStats.count : 0}
            weeklyPrompt={weeklyPrompt}
          ></UserStats>
        </Box>
      )}
    </>
  );

  return (
    <>
      {!loading && (
        <>
          {/*Serve Button*/}
          <center>
            <Button
              aria-label="i-served-this-week-button"
              variant="contained"
              onClick={handleOpen}
              hidden={didIt}
              size="large"
            >
              <Typography fontSize={getButtonFontSize()}>
                I served this week
              </Typography>
            </Button>
          </center>

          {/*Served Description*/}
          {didIt && !inModal && (
            <>
              <Typography
                marginRight="3dvw"
                marginLeft="3dvw"
                marginBottom="5dvh"
                sx={{
                  fontSize: "min(1.5em, 4vw)",
                }}
              >
                Come back next Monday after midnight PST for a new prompt. Thank
                you for serving this week!
              </Typography>
            </>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              hidden={!didIt || inModal}
              sx={{
                marginRight: "1dvh",
                padding: 0,
                width: getButtonWidth(),
                fontSize: getButtonFontSize(),
              }}
            >
              <AnalyticsModal
                message="Stats"
                iconAlignment="right"
              ></AnalyticsModal>
            </Box>
            {/*Share Button*/}
            <Box
              sx={{ width: getButtonWidth(), fontSize: getButtonFontSize() }}
            >
              <ShareButton
                hidden={!didIt}
                weeklyPrompt={weeklyPrompt}
                promptCompleted={didIt}
                inModal={inModal}
              ></ShareButton>
            </Box>
          </Box>

          {/*Served Modal*/}
          {!loading && !inModal && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="did-it-modal-title"
              aria-describedby="did-it-modal-description"
            >
              {didItModalBody}
            </Modal>
          )}
        </>
      )}
    </>
  );
}
