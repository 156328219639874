import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import ShareButton from "./ShareButton";
import { Typography, Box, Link, Divider } from "@mui/material";
import SubscribeModal from "./SubscribeModal";

/* eslint-disable react/prop-types */
export default function UserStats(props) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const [globalCount, setGlobalCount] = useState(0);
  const [inModal, setInModal] = useState(false);
  const [weeklyPrompt, setWeeklyPrompt] = useState({});

  useEffect(() => {
    setGlobalCount(props.globalCount);
  }, [props.globalCount]);

  useEffect(() => {
    setInModal(props.inModal);
  }, [props.inModal]);

  useEffect(() => {
    setWeeklyPrompt(props.weeklyPrompt);
  }, [props.weeklyPrompt]);

  useEffect(() => {
    setCurrentUser(JSON.parse(localStorage.getItem("profile")));
  }, [localStorage.getItem("profile")]);

  const fontSizeCountInModal = () => {
    if (inModal) {
      return "min(2em, 2vh)";
    } else return "2.5em";
  };

  const fontSizeTextInModal = () => {
    if (inModal) {
      return "min(1em, 2vh)";
    } else return "1em";
  };

  return (
    <>
      <Stack
        direction="row"
        width="inherit"
        spacing={2}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            color="#A9A9A9"
            sx={{
              opacity: "80%",
            }}
          />
        }
      >
        <Stack
          direction="column"
          width="50%"
          alignItems="center"
          justifyContent="center"
        >
          <Typography fontSize={fontSizeTextInModal()}>YOUR STATS</Typography>
          <Typography
            fontWeight="bold"
            fontSize={fontSizeCountInModal()}
            textOverflow="ellipsis"
            maxWidth="250px"
            noWrap
            overflow="hidden"
          >
            {currentUser.total_prompts_completed}
          </Typography>
          <Typography fontSize={fontSizeTextInModal()}>
            Served All Time
          </Typography>
        </Stack>
        <Stack direction="column" width="50%" sx={{ alignItems: "center" }}>
          <Typography fontSize={fontSizeTextInModal()}>GLOBAL STATS</Typography>
          <Typography
            fontWeight="bold"
            fontSize={fontSizeCountInModal()}
            textOverflow="ellipsis"
            maxWidth="170px"
            noWrap
            overflow="hidden"
          >
            {globalCount}
          </Typography>
          <Typography fontSize={fontSizeTextInModal()}>
            Served this Week
          </Typography>
        </Stack>
      </Stack>
      {!inModal && weeklyPrompt && (
        <center>
          <Typography marginTop="3dvh">
            Thank you for serving! Come back next Monday after midnight PST for
            a new prompt.{" "}
          </Typography>

          <Box sx={{ width: "150px", marginTop: "3dvh" }}>
            <ShareButton
              weeklyPrompt={weeklyPrompt}
              promptCompleted={currentUser.prompt_completed_this_week || false}
            ></ShareButton>
          </Box>

          <strong>
            <Box marginTop="3vh">
              <SubscribeModal message="Subscribe for weekly reminders"></SubscribeModal>
            </Box>
            <Box marginTop="0.5vh">
              <Link
                href="https://www.instagram.com/weeklyserve/"
                target="_blank"
                sx={{
                  cursor: "pointer",
                  fontSize: "1em",
                }}
              >
                Follow on Instagram
              </Link>
            </Box>
          </strong>
        </center>
      )}
    </>
  );
}
