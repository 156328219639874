import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import PromptInfoPanel from "./PromptInfoPanel";
import DidItModal from "./DidItModal";
import { Divider } from "@mui/material";

dayjs.extend(isBetweenPlugin);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});
dayjs.tz.setDefault("America/Los_Angeles");

export default function Calendar() {
  const [highlightedWeeks, setHighlightedWeeks] = useState([]);
  const [allPrompts, setAllPrompts] = useState([]);
  const [completedPrompts, setCompletedPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState([]);
  const [hoveredDay, setHoveredDay] = React.useState(null);
  const [value, setValue] = useState(dayjs.tz());

  const fetchAllPrompts = () => {
    if (localStorage.getItem("all_prompts")) {
      setAllPrompts(JSON.parse(localStorage.getItem("all_prompts")));
    }
  };

  const getMinDate = () => {
    if (allPrompts) {
      const dates = allPrompts.map(
        (prompt) => new Date(prompt.date_used.seconds * 1000)
      );
      const earliestDate = new Date(Math.min(...dates));
      return dayjs.tz(earliestDate);
    }
  };

  const getMaxDate = () => {
    if (allPrompts) {
      const dates = allPrompts.map(
        (prompt) => new Date(prompt.date_used.seconds * 1000)
      );
      const latestDate = new Date(Math.max(...dates));
      return dayjs.tz(latestDate);
    }
  };

  const setPromptsCompletedByUser = () => {
    const currentUser = JSON.parse(localStorage.getItem("profile"));
    let completedPromptIds = [];

    if (currentUser) {
      completedPromptIds = currentUser.completed_prompt_ids;
    }

    let userCompletedPrompts = [];

    allPrompts.forEach((prompt) => {
      if (completedPromptIds.includes(prompt.weekly_prompt_id)) {
        userCompletedPrompts.push(prompt);
      }
    });

    setCompletedPrompts(userCompletedPrompts);
  };

  const setHighlightedWeeksForMonth = (date) => {
    setHighlightedWeeks([]);

    var weeksToHighlight = [];
    completedPrompts.forEach((prompt) => {
      const promptDate = new Date(prompt.date_used.seconds * 1000);
      const nextSunday = getNextSunday(dayjs.tz(promptDate));
      const formattedDate = new Date(date);

      if (
        isInSameMonth(dayjs.tz(promptDate), dayjs.tz(formattedDate)) ||
        isInSameMonth(dayjs.tz(nextSunday), dayjs.tz(formattedDate))
      ) {
        weeksToHighlight.push(dayjs.tz(promptDate));
      }
    });
    setHighlightedWeeks(weeksToHighlight);
  };

  const setPromptPanel = (date) => {
    setSelectedPrompt({});

    allPrompts.forEach((prompt) => {
      const promptDate = new Date(prompt.date_used.seconds * 1000);

      if (isInSameWeek(dayjs.tz(promptDate.getTime()), date)) {
        setSelectedPrompt(prompt);
        return;
      }
    });
  };

  useEffect(() => {
    fetchAllPrompts();
  }, []);

  useEffect(() => {
    setPromptsCompletedByUser();
    setPromptPanel(value);
  }, [allPrompts]);

  useEffect(() => {
    setPromptPanel(value);
  }, [value]);

  useEffect(() => {
    setHighlightedWeeksForMonth(value);
  }, [completedPrompts]);

  useEffect(() => {
    const storageEventHandler = (event) => {
      if (event.key === "profile") {
        fetchAllPrompts();
      }
    };

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="inherit"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            maxWidth={{ xs: "inherit", md: "9000px" }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                color="#A9A9A9"
                sx={{
                  opacity: "80%",
                }}
              />
            }
          >
            <Stack width={{ xs: "100%", md: "48%" }}>
              <Stack
                display={{ xs: "flex", md: "none" }}
                paddingLeft="2vw"
                paddingRight="2vw"
              >
                <PromptInfoPanel prompt={selectedPrompt}></PromptInfoPanel>
                <hr></hr>
              </Stack>
              <Stack width={{ md: "375px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={value}
                    onChange={(newValue) => {
                      if (!isInSameMonth(value, newValue)) {
                        setHighlightedWeeksForMonth(newValue);
                      }
                      setValue(newValue);
                    }}
                    onMonthChange={setHighlightedWeeksForMonth}
                    showDaysOutsideCurrentMonth
                    disableFuture
                    timezone="America/Los_Angeles"
                    slots={{ day: Day }}
                    minDate={getMinDate()}
                    slotProps={{
                      day: (ownerState) => ({
                        minDate: getMinDate(),
                        maxDate: getMaxDate(),
                        highlightedWeeks,
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null),
                      }),
                    }}
                  />
                </LocalizationProvider>
                <Typography align="center" fontStyle="italic" fontSize="0.7em">
                  Calendar is displayed in PST timezone
                </Typography>
              </Stack>
            </Stack>
            <Stack
              display={{ xs: "none", md: "flex" }}
              padding={2}
              alignItems="center"
              justifyContent="center"
              width={{ md: "375px" }}
            >
              <PromptInfoPanel prompt={selectedPrompt}></PromptInfoPanel>
            </Stack>
          </Stack>
        </Box>
        <Box marginTop={{ xs: "2dvh", md: "4dvh" }} marginBottom="2dvh">
          <DidItModal inModal={true} weeklyPrompt={selectedPrompt}></DidItModal>
        </Box>
      </Box>
    </>
  );
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary.light,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.light,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.primary.dark,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

const isInSameMonth = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "month");
};

const getPreviousMonday = (date) => {
  const nextMonday = new Date(
    new Date(date).setFullYear(date.$y, date.$M, date.$D)
  );
  const currentDay = nextMonday.getDay();

  // Calculate the difference to the previous Monday (unless it's already Monday)
  if (currentDay !== 1) {
    const difference = currentDay === 0 ? 6 : currentDay - 1;
    nextMonday.setDate(nextMonday.getDate() - difference);
  }

  return nextMonday;
};

function getNextSunday(date) {
  const nextSunday = new Date(
    new Date(date).setFullYear(date.$y, date.$M, date.$D)
  );
  const currentDay = nextSunday.getDay();

  // Calculate the to the next Sunday (unless it's already Monday)
  if (currentDay !== 0) {
    const daysToAdd = 7 - currentDay;
    nextSunday.setDate(nextSunday.getDate() + daysToAdd);
  }

  return nextSunday;
}

const getBadgeForIncomplete = (date, minDate, maxDate) => {
  const newDate = new Date(date).setHours(0, 0, 0, 0);
  const newMinDate = new Date(minDate).setHours(0, 0, 0, 0);
  const newMaxDate = new Date(maxDate).setHours(0, 0, 0, 0);

  if (newDate >= newMinDate && newDate <= newMaxDate) {
    if (date.$W === 1) {
      return "❌";
    }
  } else {
    return undefined;
  }
};

/* eslint-disable react/prop-types */
function Day(props) {
  const {
    minDate,
    maxDate,
    highlightedWeeks = [],
    day,

    selectedDay,
    hoveredDay,
    ...other
  } = props;

  let isSelected = false;
  highlightedWeeks.forEach((promptDate) => {
    if (
      isInSameWeek(promptDate, day) &&
      getPreviousMonday(promptDate).getDate() === day.date()
    ) {
      isSelected = true;
    }
  });

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? "✅" : getBadgeForIncomplete(day, minDate, maxDate)
      }
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <CustomPickersDay
        {...other}
        day={day}
        width="100%"
        disableMargin
        timezone="America/Los_Angeles"
        selected={false}
        outsideCurrentMonth
        isSelected={isInSameWeek(day, selectedDay)}
        isHovered={isInSameWeek(day, hoveredDay)}
      />
    </Badge>
  );
}
