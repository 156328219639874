import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { firestore } from "../Firebase";

export default function Unsubscribe() {
  const [hasToken, setHasToken] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setUserId(searchParams.get("token"));
  }, []);

  useEffect(() => {
    if (userId) {
      setHasToken(true);
      unsubscribeUser(userId);
    }
  }, [userId]);

  const unsubscribeUser = async (userId) => {
    const emailSubRef = firestore.collection("email-subs");
    try {
      await emailSubRef.doc(userId).update({
        is_subscribed: false,
      });
    } catch (error) {
      setHasToken(false);
    }
  };

  return (
    <>
      {hasToken && (
        <>
          You have successfully unsubscribed from Weekly Serve email reminders.
          If you would like to subscribe again, please go to{" "}
          <a href="https://weeklyserve.com">weeklyserve.com</a>.
        </>
      )}
      {!hasToken && <>Unable to unsubscribe user because no token was found.</>}
    </>
  );
}
