import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Item from "@mui/material/Stack";
import FeedbackIcon from "@mui/icons-material/Feedback";
import BugReportIcon from "@mui/icons-material/BugReport";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

/* eslint-disable react/prop-types */
export default function FeedbackModal(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const feedbackModalBody = (
    <Box
      sx={{
        overflow: "auto",
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        maxHeight: "350px",
        maxWidth: { xs: "450px", md: "500px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: 5,
        borderRadius: "20px",
      }}
    >
      <Box
        style={{
          flex: "0 0 50%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1dvh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ color: "black" }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </Box>

      <Box
        marginBottom="2dvh"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={3}>
          <Item>
            <LightbulbIcon
              fontSize="large"
              sx={{ color: "#FFC300", opacity: "60%" }}
            ></LightbulbIcon>
          </Item>
          <Item flexGrow={1}>
            <Typography textAlign="left" fontSize="1.3em">
              <a href="mailto:weeklyserve@gmail.com?subject=Weekly Serve Feedback">
                Provide Feedback
              </a>
            </Typography>
          </Item>
        </Stack>
      </Box>

      <hr></hr>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={3}>
          <Item>
            <BugReportIcon fontSize="large"></BugReportIcon>
          </Item>
          <Item flexGrow={1}>
            <Typography textAlign="left" fontSize="1.3em">
              <a href="mailto:weeklyserve@gmail.com?subject=Weekly Serve Bug">
                Report a Bug
              </a>
            </Typography>
          </Item>
        </Stack>
      </Box>

      <hr></hr>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography textAlign="center" fontSize="1.2em" marginTop="2dvh">
          Or, email{" "}
          <a href="mailto:weeklyserve@gmail.com?subject=Weekly Serve Feedback">
            weeklyserve@gmail.com
          </a>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        aria-label="feedback-icon"
        onClick={handleOpen}
        sx={{
          borderRadius: "inherit",
          width: "inherit",
          paddingRight: { xs: "5px", md: "10px" },
          paddingLeft: { xs: "5px", md: "10px" },
        }}
      >
        <FeedbackIcon></FeedbackIcon>
        {message && (
          <Typography
            paddingLeft={1}
            paddingRight={1}
            width="inherit"
            textAlign="left"
          >
            {message}
          </Typography>
        )}
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        {feedbackModalBody}
      </Modal>
    </>
  );
}
