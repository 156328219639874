import React, { useContext, useState } from "react";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const [loading] = useState(false);

  return (
    <AuthContext.Provider value="">{!loading && children}</AuthContext.Provider>
  );
}
