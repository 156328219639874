import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HelpIcon from "@mui/icons-material/Help";
import { Typography, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubscribeModal from "./SubscribeModal";

export default function HelpModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("profile"));
    if (currentUser && !currentUser.saw_helper_modal) {
      setOpen(true);
      const profile = {
        ...currentUser,
        saw_helper_modal: true,
      };
      localStorage.setItem("profile", JSON.stringify(profile));
    }
  }, [localStorage.getItem("profile")]);

  const helpModalBody = (
    <Box
      sx={{
        overflow: "auto",
        position: "absolute",
        margin: "auto",
        top: { xs: 65, md: 0 },
        bottom: 0,
        left: 0,
        right: 0,
        maxHeight: { xs: "100%", md: "90%" },
        maxWidth: { xs: "100%", md: "720px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: 5,
        borderRadius: { xs: "20px 20px 0 0", md: "20px" },
      }}
    >
      <Box
        style={{
          flex: "0 0 50%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1dvh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ color: "black" }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </Box>

      <Box>
        <center>
          <Typography
            variant="h1"
            fontSize={{ xs: "1.5em", md: "2em" }}
            fontWeight="bold"
            paddingBottom="2dvh"
          >
            Serve Someone This Week
          </Typography>

          <Typography fontSize={{ xs: "1em", md: "1.2em" }}>
            <em>
              &quot;The best way to find yourself is to lose yourself in the
              service of others&quot; —Mahatma Gandhi
            </em>
          </Typography>
        </center>
        <Typography component="div" fontSize={{ xs: "1em", md: "1.2em" }}>
          <ol>
            <li style={{ margin: "3dvh 0" }}>
              🤝 Do your best to serve someone this week. Use the prompt to
              inspire you!
            </li>
            <li style={{ margin: "3dvh 0" }}>
              🙌 Once you&apos;ve served someone, click the &quot;I SERVED THIS
              WEEK&quot; button to track your progress
            </li>
            <li style={{ margin: "3dvh 0" }}>
              ✍️ Journal about your experience and share it with friends
            </li>
            <li style={{ margin: "3dvh 0" }}>
              📊 Check out stats to view your progress and previous prompts
            </li>
          </ol>
        </Typography>
        <hr></hr>
        <Typography
          textAlign="center"
          fontSize={{ xs: "1em", md: "1.2em" }}
          marginTop="3dvh"
        >
          A new prompt is released every Monday at midnight PST
        </Typography>

        <center>
          <Box marginTop="1dvh">
            <strong>
              <SubscribeModal message="Subscribe for weekly reminders"></SubscribeModal>
            </strong>
          </Box>
        </center>
        <center>
          <Box marginTop="0.5vh">
            <strong>
              <Link
                href="https://www.instagram.com/weeklyserve/"
                target="_blank"
                sx={{
                  cursor: "pointer",
                  fontSize: "1em",
                }}
              >
                Follow on Instagram
              </Link>
            </strong>
          </Box>
        </center>
      </Box>
    </Box>
  );

  return (
    <div>
      <IconButton
        size="large"
        color="inherit"
        aria-label="help-icon"
        onClick={handleOpen}
        sx={{
          paddingRight: { xs: "6px", md: "11px" },
          paddingLeft: { xs: "6px", md: "11px" },
        }}
      >
        <HelpIcon></HelpIcon>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="help-modal-title"
        aria-describedby="help-modal-description"
      >
        {helpModalBody}
      </Modal>
    </div>
  );
}
