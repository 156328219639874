import React, { useEffect, useState } from "react";

import { Typography, Box } from "@mui/material";

/* eslint-disable react/prop-types */
export default function PromptInfoPanel(props) {
  const [prompt, setPrompt] = useState({});

  useEffect(() => {
    setPrompt(props.prompt);
  }, [props.prompt]);

  return (
    <>
      {prompt.prompt && (
        <>
          <Box align="center">
            <Typography
              variant="body2"
              align="center"
              fontWeight="bold"
              lineHeight="24px"
              fontSize="min(1.6em, 5vw)"
            >
              <div dangerouslySetInnerHTML={{ __html: prompt.prompt }} />
            </Typography>
            <Typography
              fontSize="min(1.2em, 5vw)"
              marginTop="0.5dvh"
              lineHeight="24px"
            >
              <div dangerouslySetInnerHTML={{ __html: prompt.description }} />
            </Typography>
            <Typography marginTop="2dvh">{prompt.emoji_description}</Typography>
            <Typography
              align="center"
              marginTop="2dvh"
              sx={{ fontSize: "0.7em", fontStyle: "italic" }}
            >
              Prompt used on the week of{" "}
              {new Date(prompt.date_used.seconds * 1000).toDateString()}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
